let { hfLeadUniqueID, renewalPaymentAmount, sameAsCashPrice  } = RAC.coreDataLayer;
let preApprovalWeeklyAmountOfferedAgrConfirmation = RAC.coreDataLayer.preApprovalWeeklyAmountOffered;
function zync_call() {
	let productQuantity;
	// Check if startOrderJSON is present
	if (RAC.coreDataLayer.startOrderJSON) {
		// Access productQuantity if startOrderJSON exists
		productQuantity = RAC.coreDataLayer.startOrderJSON.productQuantity;
	}

	let z = document.createElement("script");
	let zmpID = $('#zetaAgrConfirmConfigs').data('zeta-zmp-id');
	let currency = $('#zetaAgrConfirmConfigs').data('zeta-currency');
	let OrderID = hfLeadUniqueID;
	let OrderAmount = renewalPaymentAmount;
	let OrderQty = productQuantity;
	let custom3 = sameAsCashPrice;
	let custom4 = preApprovalWeeklyAmountOfferedAgrConfirmation;
	let cache_buster = $('#zetaAgrConfirmConfigs').data('zeta-cache-buster');
	let rental_pixel = $('#zetaAgrConfirmConfigs').data('zeta-rezync-rental-pixel');
	let zeta_rezync_url = $('#zetaAgrConfirmConfigs').data('zeta-rezync-url'); // Get zeta-rezync-url
	// Construct the z_src URL
	let z_src = isNullUndefined(zeta_rezync_url) ? '' : zeta_rezync_url;

	if (!isNullUndefined(rental_pixel)) {
		z_src += "&k=" + rental_pixel;
	}
	if (!isNullUndefined(zmpID)) {
		z_src += "&zmpID=" + zmpID;
	}
	if (!isNullUndefined(currency)) {
		z_src += "&currency=" + currency;
	}
	if (!isNullUndefined(OrderID)) {
		z_src += "&OrderID=" + OrderID;
	}
	if (!isNullUndefined(OrderAmount)) {
		z_src += "&OrderAmount=" + OrderAmount;
	}
	if (!isNullUndefined(OrderQty)) {
		z_src += "&OrderQty=" + OrderQty;
	}
	if (!isNullUndefined(custom3)) {
		z_src += "&custom3=" + custom3;
	}
	if (!isNullUndefined(custom4)) {
		z_src += "&custom4=" + custom4;
	}
	if (!isNullUndefined(cache_buster)) {
		z_src += "&cache_buster=" + Date.now();
	}

	// Set the src attribute of the script element
	z.setAttribute("src", z_src);
	z.setAttribute("data-cookie-purpose", "Advertising");
	document.body.appendChild(z);
}
if (['complete', 'interactive'].indexOf(document.readyState) >= 0) {
	zync_call();
} else {
	window.addEventListener("DOMContentLoaded", function () {
		zync_call();
	});
}